import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Cardgrid from "../../components/cardgrid";
import Card from "../../components/card";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Founding`}</h1>
    <p><strong parentName="p">{`DesignInquiry is a collaborative non-conference founded by Peter Hall, Margo Halverson, and Melle Hammer. It received its not-for-profit status in 2007. A diverse and lively cast of thinkers, makers, practitioners continue to contribute to DesignInquiry’s expanding and evolving notion of design and work.`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGPSw6JFChf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAgMAARIEERT/2gAIAQEAAQUC49BmxT20api24nohFov/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAECEBExEyFxkaH/2gAIAQEABj8C3im+C2muiSWKY9Ln/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERITFBUf/aAAgBAQABPyFyLTsI3oEccsJcTp6oXIh//9oADAMBAAIAAwAAABDv7//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxClf//EABcRAQADAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8QsOR//8QAGhABAQADAQEAAAAAAAAAAAAAAREAIUExUf/aAAgBAQABPxA0hJllNauBafgWYRADgPDmJHaVRmLX1/cdhOW5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poor Farm",
          "title": "Poor Farm",
          "src": "/static/06876a7b25516cded1a9fdf1d6cf0ec8/e5166/DSCN5248.jpg",
          "srcSet": ["/static/06876a7b25516cded1a9fdf1d6cf0ec8/f93b5/DSCN5248.jpg 300w", "/static/06876a7b25516cded1a9fdf1d6cf0ec8/b4294/DSCN5248.jpg 600w", "/static/06876a7b25516cded1a9fdf1d6cf0ec8/e5166/DSCN5248.jpg 1200w", "/static/06876a7b25516cded1a9fdf1d6cf0ec8/d9c39/DSCN5248.jpg 1800w", "/static/06876a7b25516cded1a9fdf1d6cf0ec8/e1596/DSCN5248.jpg 2048w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`one >`}</strong>{` Margo Halverson was founder and director of msigd `}<em parentName="p">{`Maine Summer Institute Graphic Design`}</em>{` beginning in 1992. She invited Melle Hammer as faculty in 2000 & 2002.`}</p>
    <p><strong parentName="p">{`two >`}</strong>{` Together Margo & Melle designed a new program, inviting Peter Hall to help drive this experiment in 2004.`}</p>
    <p><strong parentName="p">{`three >`}</strong>{` DesignInquiry left the umbrella institution Maine College of Art and began again on an island off Maine so we could all cook. Literally.`}</p>
    <p><strong parentName="p">{`four >`}</strong>{` In 2007 DesignInquiry became an educational 501(c)3 nonprofit organization with a board of directors. Now we’re flying, grateful, busy, determined, and happy.`}</p>
    <hr></hr>
    <Cardgrid mdxType="Cardgrid">
    <Card link="https://archive.designinquiry.net/about/history/from-mainland-to-island/" imagepath="/Screen-Shot-2014-12-10-at-7.21.32-PM.png" title="From Mainland to Island" mdxType="Card" />
    <Card link="https://archive.designinquiry.net/about/history/designinquiryan-anecdote/" imagepath="/vert-scrollDIthingy12.jpg" title="DesignInquiry…An Anecdote" mdxType="Card" />
    <Card link="https://archive.designinquiry.net/about/history/the-ampersand-as-a-place/" imagepath="/09-624x475.jpg" title="The Ampersand as a Pace" mdxType="Card" />
    </Cardgrid>
    <hr></hr>
    <h3>{`History`}</h3>
    <p><strong parentName="p">{`Maine Summer Institute of Graphic Design, 1992–2002`}</strong></p>
    <p><strong parentName="p">{`2002`}</strong></p>
    <ul>
      <li parentName="ul">{`Elliott Earls`}</li>
      <li parentName="ul">{`Michael Rock & Susan Sellers`}</li>
      <li parentName="ul">{`Lorraine Wild`}</li>
      <li parentName="ul">{`Melle Hammer`}</li>
      <li parentName="ul">{`Mark Jamra`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "202.66666666666669%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAApABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAF6zhrYoGOfr14UlwsdBdCNpECt/8QAHBAAAwADAAMAAAAAAAAAAAAAAQIDEBIhADJB/9oACAEBAAEFAnPjXZFi5onxhMiapqeGnayTZTMEtAEzXRTmvqmP/8QAGREBAAIDAAAAAAAAAAAAAAAAAAECEBEx/9oACAEDAQE/AcdblVL/xAAbEQACAgMBAAAAAAAAAAAAAAAAAQMhAhARMv/aAAgBAgEBPwHVYWxcdky6iPyj/8QAHRAAAgICAwEAAAAAAAAAAAAAAAECESExECAiUf/aAAgBAQAGPwJu3v6JbLFhuhKcqZ5lga5eS7K7/wD/xAAdEAACAwEBAAMAAAAAAAAAAAAAAREhMVFhEHGB/9oACAEBAAE/IWJs1Y3RWrXv2Sj2BJJHoJEfJmuWUU6DRmSO2fgljhaPKe8GGUTYm5Gv06T89Yf/2gAMAwEAAgADAAAAEDQ284Tv/8QAGREBAQEAAwAAAAAAAAAAAAAAAQARITFR/9oACAEDAQE/EEYD2VGFhxLLs3//xAAYEQEBAQEBAAAAAAAAAAAAAAABABFBMf/aAAgBAgEBPxARc2dlby2Q47dWFf/EAB0QAQADAQEAAwEAAAAAAAAAAAEAESExQXGBsZH/2gAIAQEAAT8QS+aaBQUwHnIrI2r6Ag+eRQhd8ecjlcvQO3A1XV7GyfxILZCWL3sXnDK+4wIJVhwcmO+4CuYTX8pQ4uDDXOHkcB2VkmujXstTTjCk7/M5/v6zhP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Elliott Earls, Michael Rock & Susan Sellers, Lorraine Wild, Melle Hammer, Mark Jamra, Margo Halverson",
          "title": "Poster design by Elliott Earls, Michael Rock & Susan Sellers, Lorraine Wild, Melle Hammer, Mark Jamra, Margo Halverson",
          "src": "/static/b99b5f16d18d001482fa4e1bb7b5d69a/e5166/msigd-2002.jpg",
          "srcSet": ["/static/b99b5f16d18d001482fa4e1bb7b5d69a/f93b5/msigd-2002.jpg 300w", "/static/b99b5f16d18d001482fa4e1bb7b5d69a/b4294/msigd-2002.jpg 600w", "/static/b99b5f16d18d001482fa4e1bb7b5d69a/e5166/msigd-2002.jpg 1200w", "/static/b99b5f16d18d001482fa4e1bb7b5d69a/f025f/msigd-2002.jpg 1676w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Elliott Earls, Michael Rock & Susan Sellers, Lorraine Wild, Melle Hammer, Mark Jamra, Margo Halverson`}</em></p>
    <p><strong parentName="p">{`2001`}</strong></p>
    <ul>
      <li parentName="ul">{`Douglass Scott `}<em parentName="li">{`Inspiration and Influences: New Ways of Thinking about Design`}</em></li>
      <li parentName="ul">{`Frans Oosterhoff `}<em parentName="li">{`Recharge: Exercises in How to Analyze, Transform & Reload Images`}</em></li>
      <li parentName="ul">{`Lucille Tenazas `}<em parentName="li">{`Lost/Found: Journey Through Public Space`}</em></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "136%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQL/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGldOZOlp0JKh1ILWkLCf/EABsQAAIDAQEBAAAAAAAAAAAAAAECAAMREiEi/9oACAEBAAEFAjcRDd8kmWjZqqQvlzZdyWmxyQqWv2Z//8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQEv/aAAgBAwEBPwFQJuf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEh/8QAHhAAAgIBBQEAAAAAAAAAAAAAAAERIQIQEiIxQVH/2gAIAQEABj8C5LwTxtFEefTYp7sQqbolLLdPejMb0//EABsQAQADAQEBAQAAAAAAAAAAAAEAESExQVFx/9oACAEBAAE/IUUDLdmNfZrkXSyoryW+uRykGiQX8vIrKHBWQereiPGVnIpjoR4e+4R7P//aAAwDAQACAAMAAAAQQCeA/8QAGREBAAMBAQAAAAAAAAAAAAAAAQARIRAx/9oACAEDAQE/EFFssMhgK85//8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxCsq9sf/8QAHBABAQADAAMBAAAAAAAAAAAAAREAIUExUXFh/9oACAEBAAE/ELpdyoVThOYexrCQ42bZ3ECA96VfO7yAoLyWrtOZX6SQ0McS0EIXVPbm4ujIdEnzJG5RVz8yTZKMuICxweYvzEd3P//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Melle Hammer",
          "title": "Poster design by Melle Hammer",
          "src": "/static/0ca2e39c416a75ac27e91161d3b83a17/e5166/msigd-20011.jpg",
          "srcSet": ["/static/0ca2e39c416a75ac27e91161d3b83a17/f93b5/msigd-20011.jpg 300w", "/static/0ca2e39c416a75ac27e91161d3b83a17/b4294/msigd-20011.jpg 600w", "/static/0ca2e39c416a75ac27e91161d3b83a17/e5166/msigd-20011.jpg 1200w", "/static/0ca2e39c416a75ac27e91161d3b83a17/d9c39/msigd-20011.jpg 1800w", "/static/0ca2e39c416a75ac27e91161d3b83a17/df51d/msigd-20011.jpg 2400w", "/static/0ca2e39c416a75ac27e91161d3b83a17/fe90e/msigd-20011.jpg 2420w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Melle Hammer`}</em></p>
    <p><strong parentName="p">{`2000`}</strong></p>
    <ul>
      <li parentName="ul">{`Bruno Monguzzi `}<em parentName="li">{`Image Interaction/Interacting Images`}</em></li>
      <li parentName="ul">{`Melle Hammer `}<em parentName="li">{`A room with a view/for a few–a journey through typography`}</em></li>
      <li parentName="ul">{`Nancy Skolos/Thomas Wedell `}<em parentName="li">{`Type, Image, Meaning`}</em></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "142.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABYBAQEBAAAAAAAAAAAAAAAAAAECAP/aAAwDAQACEAMQAAABtXTGFQHUxvNRuID/AP/EABwQAAMAAQUAAAAAAAAAAAAAAAABEQIDEBIhIv/aAAgBAQABBQJ17OmXRUU1X4GN0Wb5tn//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEh/8QAFxAAAwEAAAAAAAAAAAAAAAAAARAgMf/aAAgBAQAGPwJZAr//xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhUTFx/9oACAEBAAE/IbOG9HR5RNMgMJgLX4NJ8JN4YEXhZn//2gAMAwEAAgADAAAAEDzXDP/EABcRAAMBAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QNE5//8QAFxEBAQEBAAAAAAAAAAAAAAAAABEhAf/aAAgBAgEBPxCbiOkf/8QAHhABAAICAgMBAAAAAAAAAAAAAQARITFRYUFxsdH/2gAIAQEAAT8QYEewalDdj3KWwrdyonzEcrFnDAXDKgM2L3iCobXLgquEQNs4o44Hz+xCWtT/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster Design by Wigger Bierma",
          "title": "Poster Design by Wigger Bierma",
          "src": "/static/4f76ae97f73f3091d99015322828ebde/e5166/msigd-2000.jpg",
          "srcSet": ["/static/4f76ae97f73f3091d99015322828ebde/f93b5/msigd-2000.jpg 300w", "/static/4f76ae97f73f3091d99015322828ebde/b4294/msigd-2000.jpg 600w", "/static/4f76ae97f73f3091d99015322828ebde/e5166/msigd-2000.jpg 1200w", "/static/4f76ae97f73f3091d99015322828ebde/d9c39/msigd-2000.jpg 1800w", "/static/4f76ae97f73f3091d99015322828ebde/1c0b3/msigd-2000.jpg 2380w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster Design by Wigger Bierma`}</em></p>
    <p><strong parentName="p">{`1999`}</strong></p>
    <ul>
      <li parentName="ul">{`Hans-Ulrich Allemann `}<em parentName="li">{`Graphic Identity`}</em></li>
      <li parentName="ul">{`Wigger Bierma `}<em parentName="li">{`Typography as Attitude`}</em></li>
      <li parentName="ul">{`Lucille Tenazas `}<em parentName="li">{`Visual/Verbal Exploration`}</em></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "145.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAMEAQIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAHpaIwagWFUzMkqKBZn/8QAGxABAAEFAQAAAAAAAAAAAAAAAQACEBESISL/2gAIAQEAAQUCi+lCO5VmogFRjrmy83t//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8BTsyf/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/AUyM/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQAQESESIDH/2gAIAQEABj8CbqgTirQJY3r/AP/EABoQAQEAAwEBAAAAAAAAAAAAAAEAESFRMUH/2gAIAQEAAT8hydsyR1LbBbv4YviLCTufT5eR26Q4JTKEu7N//9oADAMBAAIAAwAAABC4Avz/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8Q0YQM/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EIKmsI//xAAdEAEAAwADAAMAAAAAAAAAAAABABEhMUFRYXGR/9oACAEBAAE/EC/cc2cTtL5+QwOVdLCusUAFFHeQNq+hrEg3NtpNYlBXZLNhToXDCZDHFLovmM6LXye/UvP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster Design by Lucille Tenazas",
          "title": "Poster Design by Lucille Tenazas",
          "src": "/static/480c980af09a63b26b44e0023bf4edca/e5166/msigd-1999.jpg",
          "srcSet": ["/static/480c980af09a63b26b44e0023bf4edca/f93b5/msigd-1999.jpg 300w", "/static/480c980af09a63b26b44e0023bf4edca/b4294/msigd-1999.jpg 600w", "/static/480c980af09a63b26b44e0023bf4edca/e5166/msigd-1999.jpg 1200w", "/static/480c980af09a63b26b44e0023bf4edca/d9c39/msigd-1999.jpg 1800w", "/static/480c980af09a63b26b44e0023bf4edca/f2cf9/msigd-1999.jpg 2276w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster Design by Lucille Tenazas`}</em></p>
    <p><strong parentName="p">{`1998`}</strong></p>
    <ul>
      <li parentName="ul">{`Wolfgang Weingart `}<em parentName="li">{`Basic Typography`}</em></li>
      <li parentName="ul">{`Hans-Ulrich Allemann `}<em parentName="li">{`Graphic Identity`}</em></li>
      <li parentName="ul">{`Lucille Tenazas `}<em parentName="li">{`Experimental Typography`}</em></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "141.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAMEAQIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHpY0SE0WbWTEKNgor/xAAbEAEAAwADAQAAAAAAAAAAAAABAAIREBIiQf/aAAgBAQABBQIdFydsitQXZ9s+oxtx/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFREBAQAAAAAAAAAAAAAAAAAAIEH/2gAIAQIBAT8Bo//EABkQAAIDAQAAAAAAAAAAAAAAACAxABARIf/aAAgBAQAGPwLaUQcH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERMUFRYSH/2gAIAQEAAT8hi0yVMjpkxxWw8/hDeUbCnK0EjtJvgzliqz//2gAMAwEAAgADAAAAEMD1M//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QMS5Dbf/EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxBUiaOkdN//xAAbEAEBAQEAAwEAAAAAAAAAAAABESEAMUFhof/aAAgBAQABPxDXICm+PnCnTAw64GeqcU81EdleAABPbl51n79KsF+9bBgO5xBqcYlv10ayiOs8nf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Hans Alleman",
          "title": "Poster design by Hans Alleman",
          "src": "/static/621b18cc248f2c138c0b24291d90b854/e5166/msigd-1998.jpg",
          "srcSet": ["/static/621b18cc248f2c138c0b24291d90b854/f93b5/msigd-1998.jpg 300w", "/static/621b18cc248f2c138c0b24291d90b854/b4294/msigd-1998.jpg 600w", "/static/621b18cc248f2c138c0b24291d90b854/e5166/msigd-1998.jpg 1200w", "/static/621b18cc248f2c138c0b24291d90b854/d9c39/msigd-1998.jpg 1800w", "/static/621b18cc248f2c138c0b24291d90b854/3b140/msigd-1998.jpg 2312w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Hans Alleman`}</em></p>
    <p><strong parentName="p">{`1997`}</strong></p>
    <ul>
      <li parentName="ul">{`Nancy Skolos/Thomas Wedell `}<em parentName="li">{`Poster Form and Application`}</em></li>
      <li parentName="ul">{`Wolfgang Weingart `}<em parentName="li">{`Basic Typography`}</em></li>
      <li parentName="ul">{`Ken Heibert `}<em parentName="li">{`Image Transformation Studies`}</em></li>
      <li parentName="ul">{`Dorothea Hofmann `}<em parentName="li">{`Drawing from Still Life & Nature`}</em></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "141.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGgAAAQUBAAAAAAAAAAAAAAAAAAECAwQFBv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB0aq4GXTCBUjSFiwRlv8A/8QAHBABAAIDAAMAAAAAAAAAAAAAAgEDABEhBBIU/9oACAEBAAEFAnaa8+jab9Z8w7q7kdLJZNYELJw2TMqe/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGEP//EAB4QAAIBBAMBAAAAAAAAAAAAAAABAhARIUEiMVFh/9oACAEBAAY/ArzxfRwafw6L+Ui2tDi9mI0Ylin/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQTFR/9oACAEBAAE/IThHh6la7FxZM3eS6rrslhExNiaAUWS5WsZDgedTZhiaXyVw1Gj/2gAMAwEAAgADAAAAEO/6wf/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEFH/2gAIAQMBAT8QGE5Dn//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8Qd6RsdEP/xAAeEAEBAAEEAwEAAAAAAAAAAAABEQAhMUFRYZHBsf/aAAgBAQABPxCD0gBQyv7ixBhY2+eMQyjqo4YonpyLH56wjpURN+8sy0nDNsFFHSokbphxu4A13e80p4+4a7XWesc0k3ft84gbbZ//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Ken Hiebert",
          "title": "Poster design by Ken Hiebert",
          "src": "/static/ac7e58d2708fae7ed4d23c67149a428c/e5166/msigd-1997.jpg",
          "srcSet": ["/static/ac7e58d2708fae7ed4d23c67149a428c/f93b5/msigd-1997.jpg 300w", "/static/ac7e58d2708fae7ed4d23c67149a428c/b4294/msigd-1997.jpg 600w", "/static/ac7e58d2708fae7ed4d23c67149a428c/e5166/msigd-1997.jpg 1200w", "/static/ac7e58d2708fae7ed4d23c67149a428c/d9c39/msigd-1997.jpg 1800w", "/static/ac7e58d2708fae7ed4d23c67149a428c/6a312/msigd-1997.jpg 2396w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Ken Hiebert`}</em></p>
    <p><strong parentName="p">{`1996`}</strong></p>
    <ul>
      <li parentName="ul">{`Wolfgang Weingart `}<em parentName="li">{`Basic Typography`}</em></li>
      <li parentName="ul">{`Deborah Sussman `}<em parentName="li">{`Exploring the Environment Emphasizing Color`}</em></li>
      <li parentName="ul">{`Nancy Skolos/Thomas Wedell `}<em parentName="li">{`Poster Form and Application`}</em></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "130.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAaABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAGfIKs0yuFV26eYjZGhf//EAB0QAAICAgMBAAAAAAAAAAAAAAECAAMRIgQSITL/2gAIAQEAAQUCezZXiv44ibMmRH+04+la4UgSsntP/8QAFREBAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQMBAT8BIf/EABcRAAMBAAAAAAAAAAAAAAAAAAECEBH/2gAIAQIBAT8BjDZ//8QAHRAAAgIBBQAAAAAAAAAAAAAAAAEQMSECEUFhcf/aAAgBAQAGPwKo5OxpD9NSVlvcyUPMf//EABwQAAIDAQEBAQAAAAAAAAAAAAERACExQVFhcf/aAAgBAQABPyEGKclA19gxRpoiCyqCeWlGoz1CV9gEJ9ChvQS5oR/kOCSV04Mn/9oADAMBAAIAAwAAABB0237/xAAYEQADAQEAAAAAAAAAAAAAAAAAARExIf/aAAgBAwEBPxCCfMGgsP/EABgRAQEBAQEAAAAAAAAAAAAAAAEAESEx/9oACAECAQE/EFck7IOOT7f/xAAgEAEBAAIBAwUAAAAAAAAAAAABEQAxIUFRYRBxkeHw/9oACAEBAAE/EDLqrT+749uFc9n1iWBW0LPGEXsd7tYW4gqXcdZyOI9a0OBtN85+GNEENPzjcdTTypiq17ouPd9Yps9L/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Nancy Skolos, Thomas Wedell",
          "title": "Poster design by Nancy Skolos, Thomas Wedell",
          "src": "/static/2110b438c9d75ffdd1254e14e3f8f675/e5166/msigd-1996.jpg",
          "srcSet": ["/static/2110b438c9d75ffdd1254e14e3f8f675/f93b5/msigd-1996.jpg 300w", "/static/2110b438c9d75ffdd1254e14e3f8f675/b4294/msigd-1996.jpg 600w", "/static/2110b438c9d75ffdd1254e14e3f8f675/e5166/msigd-1996.jpg 1200w", "/static/2110b438c9d75ffdd1254e14e3f8f675/d9c39/msigd-1996.jpg 1800w", "/static/2110b438c9d75ffdd1254e14e3f8f675/df51d/msigd-1996.jpg 2400w", "/static/2110b438c9d75ffdd1254e14e3f8f675/f0cf3/msigd-1996.jpg 2456w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Nancy Skolos, Thomas Wedell`}</em></p>
    <p><strong parentName="p">{`1995`}</strong></p>
    <ul>
      <li parentName="ul">{`Wolfgang Weingart`}</li>
      <li parentName="ul">{`Steff Geissbuhler`}</li>
      <li parentName="ul">{`Dorothea Hofmann`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "126.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAH/2gAMAwEAAhADEAAAAcrfiGsUY06KJGHpDwX/AP/EAB0QAQACAQUBAAAAAAAAAAAAAAECAxEABBIyMyH/2gAIAQEAAQUC5Kk0YWqZC5kYpMxt9nrtftV3tqrp/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAECECFRYf/aAAgBAwEBPwHonglqv//EABkRAQACAwAAAAAAAAAAAAAAAAEAAhARMf/aAAgBAgEBPwENxJXmP//EABwQAAEFAAMAAAAAAAAAAAAAAAABAhARMVFhcf/aAAgBAQAGPwJdLMHXyotJsO9i+x8//8QAHhAAAgICAgMAAAAAAAAAAAAAAAERITFBEFFhcaH/2gAIAQEAAT8hbS7dTgU5NxqTDeex6F2EXTbIhs3ZW3ZjZSPN5T6Ra9rg/9oADAMBAAIAAwAAABDQHMD/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/EESGjSaOXkAGX//EABgRAQEBAQEAAAAAAAAAAAAAAAEAESEx/9oACAECAQE/EF8Sjm3epVdv/8QAHxABAAMAAgEFAAAAAAAAAAAAAQARIUFhMVFxocHw/9oACAEBAAE/EGhCV09WTTZXZ7GjRAKZUHhzMMCIjQ6itdDNeiAH5FSNayyFhN6DfBPkfqfi7ng92f/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Steff Geissbuhler",
          "title": "Poster design by Steff Geissbuhler",
          "src": "/static/aa0f6a7e00ff2dc89b445f5aee25a59b/e5166/msigd-1995.jpg",
          "srcSet": ["/static/aa0f6a7e00ff2dc89b445f5aee25a59b/f93b5/msigd-1995.jpg 300w", "/static/aa0f6a7e00ff2dc89b445f5aee25a59b/b4294/msigd-1995.jpg 600w", "/static/aa0f6a7e00ff2dc89b445f5aee25a59b/e5166/msigd-1995.jpg 1200w", "/static/aa0f6a7e00ff2dc89b445f5aee25a59b/d9c39/msigd-1995.jpg 1800w", "/static/aa0f6a7e00ff2dc89b445f5aee25a59b/50a3f/msigd-1995.jpg 2348w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Steff Geissbuhler`}</em></p>
    <p><strong parentName="p">{`1994`}</strong></p>
    <ul>
      <li parentName="ul">{`Ken Hiebert`}</li>
      <li parentName="ul">{`Wolfgang Weingart`}</li>
      <li parentName="ul">{`Dorothea Hofmann`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "189%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAmABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQL/xAAXAQEBAQEAAAAAAAAAAAAAAAACAQAD/9oADAMBAAIQAxAAAAHTzaFzPEkVsr4GGnYUzKDoKgJf/8QAHxAAAgICAQUAAAAAAAAAAAAAAQIDEgAhBBMiIzEy/9oACAEBAAEFAmYDOQ7K0Zsh8oZRJlumNEesaO5f5Ld6nTbViFaJrr//xAAWEQEBAQAAAAAAAAAAAAAAAAAQEQD/2gAIAQMBAT8B0K//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/Abg5/8QAHBAAAgICAwAAAAAAAAAAAAAAAAEQESFRAhIi/9oACAEBAAY/Ajyyy8rQjqNai7HLQuWS0f/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFBUYExYXH/2gAIAQEAAT8hwa19mnlyttdxKsAfLiC26zCkMhzMDKMIIgJqDUSJeJincTaL2BLgIDiDTUXuf//aAAwDAQACAAMAAAAQrMbCA8//xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhQf/aAAgBAwEBPxDfIbCbbI7N/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAMf/aAAgBAgEBPxCa5GXQ5Qhjm//EACAQAQACAgIBBQAAAAAAAAAAAAEAESExQYFxUWGh0fD/2gAIAQEAAT8QaAMy6X9w0qNYOSMmlbLH6GAAtVzFbhlHTfUoGEu9viKFGmmPERqgsqCSVDD95mGUZ1qWCb12qIV8oSylhfpE8QKUt37y3iwVtif/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Ken Hiebert",
          "title": "Poster design by Ken Hiebert",
          "src": "/static/cb3a13301d1119cf65fc25cd377912ec/e5166/msigd-1994.jpg",
          "srcSet": ["/static/cb3a13301d1119cf65fc25cd377912ec/f93b5/msigd-1994.jpg 300w", "/static/cb3a13301d1119cf65fc25cd377912ec/b4294/msigd-1994.jpg 600w", "/static/cb3a13301d1119cf65fc25cd377912ec/e5166/msigd-1994.jpg 1200w", "/static/cb3a13301d1119cf65fc25cd377912ec/d9c39/msigd-1994.jpg 1800w", "/static/cb3a13301d1119cf65fc25cd377912ec/31451/msigd-1994.jpg 1808w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Ken Hiebert`}</em></p>
    <p><strong parentName="p">{`1993`}</strong></p>
    <ul>
      <li parentName="ul">{`Hans Ulrich Allemann`}</li>
      <li parentName="ul">{`Inge Druckrey`}</li>
      <li parentName="ul">{`Wolfgang Weingart`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "199.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAoABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAH1IyS2Yhjqy0uckpa0KIG3/8QAHhAAAgIBBQEAAAAAAAAAAAAAAAECEQMEEBIhMSL/2gAIAQEAAQUC9b9tFrFCX2cUahziJdOzKr2sbE7R/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Ba//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AWP/xAAbEAABBAMAAAAAAAAAAAAAAAABABAgMRKBsf/aAAgBAQAGPwKQw4gwvULb/8QAGxABAQEBAQEBAQAAAAAAAAAAAREAITFBYXH/2gAIAQEAAT8hGkSGkdd+mPA8CzAIpR7ogDDVv09lb0uMxE4byaL2tTnfhuvdKz7i4h/jl3//2gAMAwEAAgADAAAAEHj5QwPv/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQEUH/2gAIAQMBAT8QgNRCLmP/xAAYEQEBAAMAAAAAAAAAAAAAAAABABARMf/aAAgBAgEBPxDCWk9gv//EAB0QAQADAAMBAQEAAAAAAAAAAAEAESExQVFhgZH/2gAIAQEAAT8QUOQpDnPe/wAjQAKOc6ny/wBgdWmSteNnDTAVHyYxA0DfbB7NuLCV5UWhABTjXYsAc6frKIF42is7qcrwa7wRQGo+X9glpvlbhBVgXekoZ//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Inge Druckrey",
          "title": "Poster design by Inge Druckrey",
          "src": "/static/b53cf9c8dde865cc378d944f9460a97b/e5166/msigd-1993.jpg",
          "srcSet": ["/static/b53cf9c8dde865cc378d944f9460a97b/f93b5/msigd-1993.jpg 300w", "/static/b53cf9c8dde865cc378d944f9460a97b/b4294/msigd-1993.jpg 600w", "/static/b53cf9c8dde865cc378d944f9460a97b/e5166/msigd-1993.jpg 1200w", "/static/b53cf9c8dde865cc378d944f9460a97b/6c50f/msigd-1993.jpg 1672w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Inge Druckrey`}</em></p>
    <p><strong parentName="p">{`1992`}</strong></p>
    <ul>
      <li parentName="ul">{`James Cross`}</li>
      <li parentName="ul">{`Rudolph de Harak`}</li>
      <li parentName="ul">{`Bruno Monguzzi`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "130.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAaABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEBQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdJF4xSTErzd87edIUP/xAAdEAABAwUBAAAAAAAAAAAAAAACAAERAxIiMTIh/9oACAEBAAEFApyftNiV0J/UfTUoYNVuRIkOv//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABoQAQACAwEAAAAAAAAAAAAAAAEAEAIRMiH/2gAIAQEABj8C1G1WeFZO+mmdNf/EAB0QAAICAgMBAAAAAAAAAAAAAAABESExUUFhcbH/2gAIAQEAAT8hV/Yk9aEbHJJ5ROx6tjQ1vReUSeFI9lHKmRn9DCf/2gAMAwEAAgADAAAAENc3Tf/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEP/aAAgBAwEBPxAgIz//xAAXEQEBAQEAAAAAAAAAAAAAAAABABAR/9oACAECAQE/EF5iQX//xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMUFRkYGhsf/aAAgBAQABPxBWvhQ3jJUxbAD4t/YKHfuUYLVQx8QYNvTvCK6fVZmxFeiQkmgvp493HZbhESxbDTGLJpty80ryhfqf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Poster design by Rudolph deHarak",
          "title": "Poster design by Rudolph deHarak",
          "src": "/static/d94ab4c9c4aa0aadf11df77a518a1c70/e5166/msigd-1992.jpg",
          "srcSet": ["/static/d94ab4c9c4aa0aadf11df77a518a1c70/f93b5/msigd-1992.jpg 300w", "/static/d94ab4c9c4aa0aadf11df77a518a1c70/b4294/msigd-1992.jpg 600w", "/static/d94ab4c9c4aa0aadf11df77a518a1c70/e5166/msigd-1992.jpg 1200w", "/static/d94ab4c9c4aa0aadf11df77a518a1c70/d9c39/msigd-1992.jpg 1800w", "/static/d94ab4c9c4aa0aadf11df77a518a1c70/df51d/msigd-1992.jpg 2400w", "/static/d94ab4c9c4aa0aadf11df77a518a1c70/f319e/msigd-1992.jpg 2496w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Poster design by Rudolph deHarak`}</em></p>
    <p><a parentName="p" {...{
        "href": "/DIhistory.pdf"
      }}>{`Download the PDF`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      